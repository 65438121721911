(function (wpm, $, undefined) {

	wpm.jQueryExists = async () => new Promise(resolve => {

		(function waitForjQuery() {
			if (typeof jQuery !== "undefined") return resolve()
			setTimeout(waitForjQuery, 100)
		})()
	})

	wpm.wpmDataLayerExists = async () => new Promise(resolve => {
		(function waitForVar() {
			if (typeof wpmDataLayer !== "undefined") return resolve()
			setTimeout(waitForVar, 50)
		})()
	})

	wpm.wpHooksExists = async () => new Promise(resolve => {
		(function waitForVar() {

			// Resolve if wp.hooks is defined
			if (typeof wp?.hooks !== "undefined") return resolve()
			setTimeout(waitForVar, 50)
		})()
	})

	/**
	 * Load all WooCommerce hooks functions
	 *
	 * Includes safeguard in case the user changed the default load order for wp-hooks, or removed it completely.
	 *
	 * @returns {Promise<void>}
	 */
	wpm.loadWcHooksFunctions = async () => {
		// Load all WooCommerce Blocks hooks
		await wpm.wpHooksExists()
		require("./wc_hooks")
	}

}(window.wpm = window.wpm || {}, jQuery))
